<script lang="ts" setup>
const { t, locale } = useI18n()

const title = 'CZKC testnet faucet'
const description = computed(() => t('head.description'))

useHead({
  title: title,
})

useSeoMeta({
  title: title,
  description: description,
  keywords: 'CZKC, testnet, faucet, crypto, stablecoin',
  ogTitle: 'CZKC testnet faucet',
  ogDescription: description,
  ogImage: `/images/og_images/${locale.value}.webp`,
  ogImageAlt: 'CZKC testnet faucet logo',
  ogType: 'website',
  twitterTitle: 'CZKC testnet faucet',
  twitterDescription: description,
  twitterCard: 'summary_large_image',
  twitterImage: `/images/og_images/${locale.value}.webp`,
  twitterImageAlt: 'CZKC testnet faucet logo',
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <slot />
  </div>
</template>
